export const priceData = [
    {
        name: "Beginner",
        addText: "з куратором",
        price: 870,
        prevPrice: 950,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b5e8e10e85afd",
        avlServices: [
            "уроки категорії Beginner",
            "документація",
            "супровід куратора та зворотній звʼязок",
            "практичні завдання з перевіркою",
            "телеграм-група MopisSchool",
            "диплом"
        ],
        notAvlServices: []
    },

    {
        name: "Pro",
        addText: "самостійний",
        price: 950,
        prevPrice: 1100,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b99aa8bdaf143",
        avlServices: [
            "уроки категорії Beginner & Pro",
            "повний пакет документації (укр та англ)",
            "поглиблені уроки із запрошеними спікерами",
            "телеграм-група MopisSchool",
            "диплом"
        ],
        notAvlServices: []
    },

    {
        name: "Pro",
        addText: "з куратором",
        price: 1300,
        prevPrice: 1400,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/bd85afdbadd5a",
        avlServices: [
           "всі уроки категорії Beginner & Pro",
            "повний пакет документації (укр та англ)",
            "поглиблені уроки із запрошеними спікерами",
            "телеграм-група MopisSchool",
            "диплом",
            "2 поглиблених вебінари від Аліси",
            "супровід куратора",
            "практичні завдання з перевіркою (рівень pro)",
            "зворотній зв'язок особисто від Аліси"

        ],
        notAvlServices: []
    },
];


//////////


export const extraPriceData = [
    {
        name: "Beginner + Pro ",
        addText: "з куратором",
        price: 1750,
        prevPrice: 1850,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b6c500f61fd38",
        avlServices: [],
        notAvlServices: []
    },

    {
        name: "Весілля в Європі",
        addText: "",
        price: 950,
        prevPrice: 1050,
        prevPriceSecond: 0,
        payLink: "https://secure.wayforpay.com/button/b00945a106f84",
        avlServices: [],
        notAvlServices: []
    },
];


////////////


export const mentoringData = [
    `Ментор — Аліса, супровід 6 місяців`,
    "Особисте планування кар’єрного шляху з Алісою",
    "практика на масштабному проєкті Mopis",
]